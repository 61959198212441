export function CoreFeatures() {
  return (
    <div className="max-w-screen-xl m-auto">
      <p className="text-center font-bold text-3xl pt-24 pb-12">Our Core Features</p>
      <div className="flex flex-col lg:flex-row mx-auto lg:pb-36 ">
        <div className="lg:w-1/4 w-full">
          <img src={'../assets/google.png'} className="h-full mx-auto" />
          <p className="text-center font-bold ">Google Calendar</p>
          <p className="text-sm text-center">
            Integrates with Google Calendar and Shared Google Calendars to view, create and update events.
          </p>
        </div>

        <div className="lg:w-1/4 w-full">
          <img src={'../assets/apple.png'} className="h-full mx-auto" />
          <p className="text-center font-bold ">Apple Calendar</p>
          <p className="text-sm text-center">
            Integrates with Apple devices and iOS calendar systems on your Apple iPad or iPhone or iMac.
          </p>
        </div>

        <div className="lg:w-1/4 w-full">
          <img src={'../assets/outlook.png'} className="h-full mx-auto" />
          <p className="text-center font-bold ">Outlook Calendar</p>
          <p className="text-sm text-center">
            Integrates with Microsoft Windows, Microsoft Teams and Outlook Calendar Systems.
          </p>
        </div>

        <div className="lg:w-1/4 w-full">
          <img src={'../assets/wall.png'} className="h-full  mx-auto" />
          <p className="text-center font-bold ">Farside Calendar</p>
          <p className="text-sm text-center">
            If you don’t use a digital calendar at all, we still get you invited and able to manage your RSVP with ease!
          </p>
        </div>
      </div>
      <div className="flex justify-center pt-8">
        <a
          className="bg-blue-700 uppercase rounded-lg font-bold text-white px-6 py-4 transition duration-300 ease-in-out hover:bg-blue-600 mr-6 text-xs"
          href="https://availabled.com"
        >
          Find Out More
        </a>
      </div>
    </div>
  );
}
