import {ReactElement} from 'react';

export function AttendingMessage<Type>(props: Type & any): ReactElement {
  const {attend} = props;

  // TODO is attending states
  let isAttendingText = '';
  let isAttendingClass = '';
  let isAttendingClassParent = '';

  if (attend?.isAttending) {
    isAttendingText = 'You are attending this event';
    isAttendingClass = 'text-green-900 text-center';
    isAttendingClassParent = 'bg-lime-200';
  } else if (!attend?.isAttending && attend?.hasResponded) {
    isAttendingText = 'You are not attending this event';
    isAttendingClass = 'text-red-900 text-center';
    isAttendingClassParent = 'bg-red-200';
  } else {
    isAttendingText = 'You have not responded to this event';
    isAttendingClass = 'text-black-900 text-center';
    isAttendingClassParent = 'bg-purple-200';
  }

  return (
    <div
      className={
        'rounded-lg w-full h-12 self-center inline-block align-middle flex items-center justify-center ' +
        isAttendingClassParent
      }
    >
      <p className={isAttendingClass}> {isAttendingText}</p>
    </div>
  );
}
