import reportWebVitals from './reportWebVitals';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { firebaseConfig, firebaseDevelopConfig } from './firebase';

import App from './App';
import './index.css';
import { FirebaseAppProvider } from 'reactfire';

ReactDOM.render(
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </FirebaseAppProvider>,
    document.getElementById('root'),
);

reportWebVitals();
