import {ReactElement} from 'react';

export function UsersStack<Type>(contact: Type & any): ReactElement {
  // TODO add number when images +5
  // TODO handle no images
  // TODO types

  const {contacts} = contact;

  return (
    <ul className="self-center inline-block align-middle flex items-center justify-center pt-8 ml-4">
      {contacts?.map((contact: any) => (
        <>
          {contact?.contact?.avatar && (<li className="-mx-2" key={contact?.contact?.name}>
            <img className="rounded-full h-16 w-16 border-2 border-white" src={contact?.contact?.avatar} />
          </li>
          )}
        </>
      ))}
    </ul>
  );
}
