import GoogleMapReact from 'google-map-react';

export function GooglePlaces<Type>(props: Type & any) {
  const {lat, lng, zoom} = props;

  const defaultProps = {
    center: {
      lat: lat,
      lng: lng,
    },
    zoom: zoom,
  };

  return (
    <div style={{height: '50vh', width: '100%'}}>
      <GoogleMapReact
        bootstrapURLKeys={{key: process.env.REACT_APP_MAPS_KEY || ''}}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        {/* @ts-ignore */}
        <Marker lat={lat} lng={lng} text="" className="w-12 h-12" />
      </GoogleMapReact>
    </div>
  );
}

function Marker(props: any) {
  return (
    <div>
      {props.text}
      <img src="../assets/marker.svg" className="h-8 w-8" />
    </div>
  );
}
