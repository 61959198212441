import {ReactElement} from 'react';

export function EventBackground(data: any): ReactElement {
  const {event} = data;
  const image = event?.groupFrom?.imageUrl ?? event?.imageUrl;

  let imageClass = `rounded-3xl h-[30rem] w-full rounded-3xl bg-cover bg-center`;
  if (image) {
    imageClass += `bg-cover bg-center bg-no-repeat`;
  } else {
    imageClass += `bg-gradient-to-r from-violet-500 to-fuchsia-500`;
  }
  return (
    <div
      className={imageClass}
      style={{
        backgroundImage: `url(${image})`,
      }}
    ></div>
  );
}
