// TODO typescript types

import {doc, updateDoc} from 'firebase/firestore';
import {ReactElement} from 'react';
import {useFirestore, useFirestoreDocData} from 'reactfire';

export function AttendingSelector<Type>(props: Type & any): ReactElement {
  const {contacts, attend, eventId, userId} = props;

  const attendRef = doc(useFirestore(), `events/${eventId}/attendees/${userId}`);

  let attendingCount = 0;
  let notAttendingCount = 0;
  let notRespondedCount = 0;

  // counter on the buttons
  contacts?.map((contact: any) => {
    if (contact?.isAttending) {
      attendingCount++;
    } else if (contact.hasResponded === false) {
      notRespondedCount++;
    } else {
      notAttendingCount++;
    }
  });

  // active classes for the buttons
  let isAttendingClass = 'text-black';
  let isNotAttendingClass = 'text-black';
  let hasNotRespondedClass = 'text-black';
  if (attend?.isAttending) {
    isAttendingClass = 'bg-lime-500 text-white';
  } else if (!attend?.isAttending && attend?.hasResponded) {
    isNotAttendingClass = 'bg-red-500 text-white';
  } else {
    hasNotRespondedClass = 'bg-purple-500 text-white';
  }

  // button click handlers
  const handleAttending = () => {
    updateDoc(attendRef, {
      isAttending: true,
      hasResponded: true,
    });
  };
  const handleNotAttending = () => {
    updateDoc(attendRef, {
      isAttending: false,
      hasResponded: true,
    });
  };
  const handleNotResponded = () => {
    updateDoc(attendRef, {
      hasResponded: false,
      isAttending: false,
    });
  };

  return (
    <div className="flex items-center justify-center">
      <div className="inline-flex  h-36 w-full" role="group">
        <button
          type="button"
          onClick={handleAttending}
          className={
            'w-full rounded-l inline-block px-6 py-2.5 font-medium text-5xl leading-tight hover:bg-lime-700 focus:outline-none focus:ring-0  transition duration-150 ease-in-out border-2 border-r-0 rounded-l-xl ' +
            isAttendingClass
          }
        >
          <span className="font-bold">{attendingCount}</span>
          <p className="text-xs">Attending</p>
        </button>
        <button
          type="button"
          onClick={handleNotAttending}
          className={
            'w-full inline-block px-6 py-2.5 font-medium text-5xl leading-tight hover:bg-red-700 focus:outline-none focus:ring-0 transition duration-150 ease-in-out border-2 ' +
            isNotAttendingClass
          }
        >
          <span className="font-bold">{notAttendingCount}</span>
          <p className="text-xs">Not Attending</p>
        </button>
        <button
          type="button"
          onClick={handleNotResponded}
          className={
            'w-full rounded-r inline-block px-6 py-2.5 font-medium text-5xl leading-tight hover:bg-purple-700 focus:outline-none focus:ring-0 transition duration-150 ease-in-out border-2 border-l-0 rounded-r-xl ' +
            hasNotRespondedClass
          }
        >
          <span className="font-bold">{notRespondedCount}</span>
          <p className="text-xs">No Response</p>
        </button>
      </div>
    </div>
  );
}
