import {collection, doc, query} from 'firebase/firestore';
import {useFirestore, useFirestoreCollectionData, useFirestoreDocData} from 'reactfire';
import {AttendingMessage} from '../components/attending-message';
import {AttendingSelector} from '../components/attending-selector';
import {CoreFeatures} from '../components/core-features';
import {EventBackground} from '../components/event-background';
import {GooglePlaces} from '../components/google-places';
import {UsersStack} from '../components/users-stack';
import {useSearchParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';

export function Event() {
  const [searchParams] = useSearchParams();
  const firestore = useFirestore();
  const user_id = searchParams.get('user_id');
  const id = searchParams.get('id');

  const eventRef = doc(firestore, `events/${id}`);
  const event = useFirestoreDocData(eventRef);

  const attendRef = doc(firestore, `events/${id}/attendees/${user_id}`);
  const attend = useFirestoreDocData(attendRef);

  const contactCollection = collection(firestore, `events/${id}/attendees`);
  const contactQuery = query(contactCollection);
  const contacts = useFirestoreCollectionData(contactQuery, {idField: 'id'});

  const geopoint = event?.data?.eventVenue?.geopoint;

  const {address, description} = event?.data?.eventVenue ?? {};

  if (event?.status === 'loading' || attend?.status === 'loading' || contacts?.status === 'loading') {
    // TODO loading screen
    return <p>Fetching ...</p>;
  }

  // TODO better date display
  const eventStartDate = new Date(event?.data?.startDate.seconds * 1000).toLocaleString('en-us', {
    hour12: true,
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
  });
  const eventEndDate = new Date(event?.data?.endDate.seconds * 1000).toLocaleString('en-us', {
    hour12: true,
    hour: 'numeric',
  });

  <Helmet>
    <title>{`We need to talk about ${event?.data?.title || 'you'}`}</title>
  </Helmet>;

  return (
    <div className="w-screen">
      <div className="container mx-auto w-full min-w-fit">
        <div className="flex flex-col justify-center lg:px-48 mt-12">
          <div className="px-12">
            <EventBackground event={event?.data} />
          </div>
          <div className="rounded-3xl bg-white  md:mx-12 :md-m-12 md:px-36 py-16 pb-0 mb-0 mx-0 mx-auto px-4 lg:px-24">
            <AttendingMessage attend={attend?.data} />

            <p className="text-center pt-8">
              {' '}
              Hey <span className="font-bold">{attend?.data?.contact?.name}</span>, you&apos;ve been invited to:{' '}
            </p>

            <p className="text-center pt-4 font-bold text-3xl">{event?.data?.title}</p>
            <p className="text-center pt-8 font-italic text-sm">Event date & time</p>
            <p className="text-center pt-2 font-bold text-sm">
              {eventStartDate.toString()} - {eventEndDate.toString()}
            </p>

            <UsersStack contacts={contacts?.data} />

            <p className="pt-8 pb-2 font-bold text-sm">Select your response</p>

            <AttendingSelector contacts={contacts?.data} attend={attend?.data} userId={user_id} eventId={id} />

            {/* <p className="text-center pt-16 font-bold text-sm">See what your friends are talking about...</p> */}

            <p className="pt-6 font-bold">About Event</p>
            <p className="pt-2 text-sm"> {description ? description : 'Theres no description for this event'}</p>

            {/* <p className="pt-6 pb-4 font-bold">Location</p> */}
          </div>
        </div>
      </div>

      {/* // TODO map makers to show place, house ,business etc */}
      {/* // TODO scale to size with change */}
      {/* <img */}
      {/*   className="w-screen h-[32rem] w-[43rem] md:w-full pb-12 static" */}
      {/*   src={`https://maps.googleapis.com/maps/api/staticmap?center=${geopoint?.latitude},${geopoint?.longitude}&zoom=11&size=${screenWidth}x150&scale=2&format=png&&visible=27&key=${process.env.REACT_APP_MAPS_KEY}`} */}
      {/* /> */}

      <div className="container mx-auto pt-12">
        <div className="flex flex-col justify-center px-12 ">
          <div className="mx-12 -m-12 md:px-40 md:pb-24 pb-0 mb-0 pt-4">
            {/* <p className="pb-4 font-bold">{event?.data.eventVenue?.description}</p> */}
            <p className="pb-4 text-sm">{address === description ? '' : address}</p>
          </div>
        </div>
      </div>
      <GooglePlaces lat={geopoint?.latitude} lng={geopoint?.longitude} zoom={12} />

      <div className="flex flex-col lg:flex-row pt-12 max-w-screen-xl m-auto">
        <img src="../assets/rectangle.svg" className="h-[50rem] absolute -z-10 rotate-45 -left-72" />
        <img src="../assets/dots.svg" className="h-[15rem] absolute -z-10  mt-[35rem]" />
        <div className="w-full lg:w-1/2  pt-4 lg:pl-24 px-8">
          <p className="font-bold text-6xl pb-4 pt-12">Plan events with real-time shared availability</p>
          <p>
            Connect to your contacts calendars and then create an event based upon their availability. Communication
            agnostic event planning.
          </p>

          <div className=" pt-12 space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
            <a
              href="https://apps.apple.com/us/app/availabled/id1560920872"
              className="w-full sm:w-auto flex bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
            >
              <svg
                className="mr-3 w-7 h-7"
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="apple"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
              >
                <path
                  fill="currentColor"
                  d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                ></path>
              </svg>
              <div className="text-left">
                <div className="mb-1 text-xs">Download on the</div>
                <div className="-mt-1 font-sans text-sm font-semibold">App Store</div>
              </div>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.experiencedigital.availabled"
              className="w-full sm:w-auto flex bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
            >
              <svg
                className="mr-3 w-7 h-7"
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="google-play"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"
                ></path>
              </svg>
              <div className="text-left">
                <div className="mb-1 text-xs">Get in on</div>
                <div className="-mt-1 font-sans text-sm font-semibold">Google Play</div>
              </div>
            </a>
          </div>
        </div>

        <video
          autoPlay
          muted
          loop
          src={'../assets/phone_vid_01.mp4'}
          className="h-[40rem] lg:h-[52rem] w-[43rem] pt-24 lg:pt-0"
        />
      </div>

      <CoreFeatures />

      <div className="pb-36" />
    </div>
  );
}
