import * as React from 'react';
import {Routes, Route, Outlet, Link} from 'react-router-dom';
import {Layout} from './layouts/public';
import {Event} from './views/event';

import {getFirestore} from 'firebase/firestore';
import {FirestoreProvider, useFirebaseApp} from 'reactfire';

export default function App() {
  const firestoreInstance = getFirestore(useFirebaseApp());
  return (
    <div className="w-screen">
      {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}

      <FirestoreProvider sdk={firestoreInstance}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="event" element={<Event />} />

            {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </FirestoreProvider>
    </div>
  );
}


function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
